import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {
  Section,
  Container,
  SectionTitle,
  SectionDescription,
  SectionLineDescription,
  ListPointer,
  ListItemPointer,
} from "../components/Section";
import ProductCarousel from "../components/ProductCarousel";
import { HeroBanner } from "../components/HeroBanner";
import { ArrowLeftIcon } from "../components/Icons";
import QuotePopUpButton from "../components/QuotePopUpButton";

const CardGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0px -8px;
  @media (min-width: 992px) {
    margin: -90px -8px 0px -8px;
  }
`;
const CardGridItem = styled.div`
  position: relative;
  width: 100%;
  padding: 0 8px;
  @media (min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
`;
const CardBase = styled.div`
  background-color: #eef0fd;
  padding: 30px 20px;
  @media (min-width: 768px) {
    padding: 60px;
  }
  @media (min-width: 992px) {
    padding: 60px 80px;
  }
  @media (min-width: 1200px) {
    padding: 60px 100px;
  }
`;

const Card = styled.div`
  background-color: #fff;
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  @media (min-width: 992px) {
    height: 100%;
    margin-bottom: 0;
  }
`;
const CardHeading = styled.div`
  background-color: #e9242a;
  color: #fff;
  text-align: center;
  padding: 20px 15px;
  @media (min-width: 768px) {
    padding: 20px;
  }
  @media (min-width: 992px) {
    padding: 30px;
  }
  @media (min-width: 1200px) {
    padding: 30px 60px;
  }
`;
const CardTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  @media (min-width: 576px) {
    font-size: 22px;
    line-height: 32px;
  }
  @media (min-width: 768px) {
    font-size: 26px;
    line-height: 36px;
  }
  @media (min-width: 992px) {
    font-size: 30px;
    line-height: 40px;
  }
  @media (min-width: 1200px) {
    font-size: 34px;
    line-height: 44px;
  }
`;
const CardBody = styled.div`
  padding: 20px;
  @media (min-width: 992px) {
    padding: 30px;
  }
`;
const Explore = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  bottom: -20px;
  @media (min-width: 1200px) {
    bottom: -24px;
  }
  @media (min-width: 1600px) {
    bottom: -30px;
  }
`;

const FinancingPageTemplate = ({ location, data }) => {
  const pageData = data.contentfulFinancingPages;
  return (
    <Layout location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription} />
      <HeroBanner>
        <GatsbyImage image={pageData.heroImage.gatsbyImageData} />
      </HeroBanner>
      <Section
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
        bgColor="#fff"
        className="section-building"
      >
        <SectionLineDescription mb="0" textAlign="center">
          <Container>
            <h1>{pageData.title}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: pageData.description.childMarkdownRemark.html,
              }}
            />
          </Container>
        </SectionLineDescription>
      </Section>

      <Section
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
        bgColor="#fff"
        className="section-terms"
      >
        <Container className="container">
          <CardBase>
            <CardGrid className="grid">
              <CardGridItem>
                <Card>
                  <CardHeading>
                    <CardTitle>{pageData.advantagesListTitle}</CardTitle>
                  </CardHeading>
                  <CardBody>
                    <ListPointer>
                      {pageData.advantages.map((item, i) => (
                        <ListItemPointer key={i}>
                          {item.content}
                        </ListItemPointer>
                      ))}
                    </ListPointer>
                  </CardBody>
                </Card>
              </CardGridItem>
              <CardGridItem>
                <Card>
                  <CardHeading>
                    <CardTitle>
                      {pageData.termsAndConditionsListTitle}
                    </CardTitle>
                  </CardHeading>
                  <CardBody>
                    <ListPointer>
                      {pageData.termsAndConditions.map((item, i) => (
                        <ListItemPointer key={i}>
                          {item.content}
                        </ListItemPointer>
                      ))}
                    </ListPointer>
                  </CardBody>
                </Card>
              </CardGridItem>
            </CardGrid>
            <Explore>
              <QuotePopUpButton
                text={pageData.applyButtonText}
                iconAfter={<ArrowLeftIcon />}
                thanksURL={
                  location.pathname === "/financing/"
                    ? "/thank-you-financing"
                    : "/thank-you-rto"
                }
              />
            </Explore>
          </CardBase>
        </Container>
      </Section>
      <Section
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
        bgColor="#fff"
        className="section-price"
      >
        <Container>
          <SectionTitle textAlign="center">
            {pageData.adviceSectionTitle}
          </SectionTitle>
          <SectionDescription mb="0" textAlign="center">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  pageData.adviceSectionDescription.childMarkdownRemark.html,
              }}
            />
          </SectionDescription>
        </Container>
      </Section>
      <ProductCarousel data={data.allContentfulProduct.edges} />
    </Layout>
  );
};
export default FinancingPageTemplate;

export const pageQuery = graphql`
  query FinancingPageTemplateQuery($id: String!) {
    contentfulFinancingPages(id: { eq: $id }) {
      metaTitle
      metaDescription
      title
      heroImage {
        gatsbyImageData(quality: 90, placeholder: BLURRED)
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      advantagesListTitle
      advantages {
        content
      }
      termsAndConditionsListTitle
      termsAndConditions {
        content
      }
      adviceSectionTitle
      adviceSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      applyButtonText
    }
    allContentfulProduct(sort: { fields: skuNumber, order: ASC }) {
      edges {
        node {
          id
          url
          rto
          financingAvailable
          skuNumber
          title
          image {
            gatsbyImageData(quality: 50, placeholder: BLURRED)
            fluid {
              srcWebp
            }
          }
          price
          width
          length
          height
          city
          state
          zipcode
          location {
            lat
            lon
          }
          buildingCondition
          description {
            childMarkdownRemark {
              html
            }
          }
          productCategories {
            title
          }
          buildingTags
          roofStyleType
          manufacturer
          gaugeTubing
          gaugePanels
        }
      }
    }
  }
`;
